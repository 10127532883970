import companies from "./companies";

const companyArray = Object.entries(companies).map(([key, company]) => company);

const getBannerCompany = () => {
    const index = companyArray && companyArray.length > 0 ? new Date().getDate() % companyArray.length : null;
    const bannerCompany = companyArray[index];
    return bannerCompany ? {
        companyId: bannerCompany.id,
        companyName: bannerCompany.name,
        companyLogo: bannerCompany.logo,
        bannerText: bannerCompany.bannerText,
        bannerTextLong: bannerCompany.bannerTextLong,
    } : null;
}

export default getBannerCompany;
import React, { useState, useEffect } from "react";
import getBannerCompany from "./bannerData";
import { companyLink } from "./links";

const bannerKey = "banner-closed";

const TenDays = 1000 * 60 * 60 * 24 * 10;

const Banner = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [isClient, setClient] = useState(false);
    const [bannerData, setBannerData] = useState(null);

    const displayBanner = () => isClient && bannerData && !bannerClosed();

    const bannerClosed = () => {
        const bannerDate = isClient && localStorage.getItem(bannerKey);
        return bannerDate && Date.parse(bannerDate) > (new Date().getTime() - TenDays);
    }

    useEffect(() => {
        setClient(true);
        setShowBanner(displayBanner());
        setBannerData(getBannerCompany());
    }, [showBanner, isClient]);

    const closeBanner = () => {
        setShowBanner(!showBanner);
        localStorage.setItem(bannerKey, new Date().toUTCString());
    }

    return bannerData ? <div className={`z-10 fixed bottom-0 inset-x-0 pb-2 sm:pb-5 ${showBanner ? "" : "hidden"}`}>
        <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="p-2 rounded-lg bg-gradient-to-r from-orange-400 to-red-600 shadow-lg sm:p-3">
                <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                        <span className="flex p-2 rounded-lg">
                            <img className="h-6 w-6" src={bannerData.companyLogo} />
                        </span>
                        <p className="text-lg font-medium text-white">
                            <span className="md:hidden">
                                {bannerData.bannerText}
                            </span>
                            <span className="hidden md:inline">
                                {bannerData.bannerTextLong}
                            </span>
                        </p>
                    </div>
                    <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <div className="rounded-md shadow-sm">
                            <a
                                onClick={() => closeBanner()}
                                href={companyLink(bannerData.companyId)} eventCategory="Sticky Banner" eventAction="Click" eventLabel={bannerData.companyId}
                                className="flex items-center justify-center px-4 py-2 border border-transparent text-base leading-5 font-medium rounded-md text-red-600 bg-white hover:text-red-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                                <span>
                                    Find out more
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                        <button onClick={() => closeBanner()} type="button" className="-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:bg-red-500 transition ease-in-out duration-150" aria-label="Dismiss">
                            <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> : <></>;
}

export default Banner;